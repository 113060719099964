exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conditions-treated-js": () => import("./../../../src/pages/conditions-treated.js" /* webpackChunkName: "component---src-pages-conditions-treated-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurance-providers-js": () => import("./../../../src/pages/insurance-providers.js" /* webpackChunkName: "component---src-pages-insurance-providers-js" */),
  "component---src-pages-notice-of-privacy-js": () => import("./../../../src/pages/notice-of-privacy.js" /* webpackChunkName: "component---src-pages-notice-of-privacy-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

